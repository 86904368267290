import * as parse5 from "parse5";

export function addClass(editorContent, blockPrefix) {
  try{
    if (!editorContent || !blockPrefix) return '';
    const doc = parse5.parseFragment(editorContent);
    const classValue = ` ${blockPrefix}__text-element `;
    for (const el of doc.childNodes) {
      el.attrs = el.attrs || [];
      const classAttr = el.attrs.find(a=> a.name === "class");
      if(classAttr){
        classAttr.value += classValue
      }
      else{
        el.attrs.push({
          name: "class",
          value: classValue
        });
      }
    }
    return parse5.serialize(doc);
  }
  catch(err){
    console.error("Failed to add class on the editor content", err);
    return editorContent || '';
  }
}
